<template>
  <div>
    <!-- <div class="my-5">
      <b-overlay show rounded="sm">
        <b-card c></b-card>
      </b-overlay>
    </div> -->
    <div v-if="!isLoading && isActive" class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <b-link class="brand-logo"> </b-link>
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="
              w-100
              d-lg-flex
              align-items-center
              justify-content-center
              px-5
            "
          >
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div>
        </b-col>
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              <b-col class="mb-5 d-flex justify-content-center"> </b-col>
              Bienvenue sur FlashCar
            </b-card-title>
            <b-card-text class="mb-2"> Veuillez vous connecter </b-card-text>

            <validation-observer ref="loginValidation">
              <b-form class="auth-login-form mt-2" @submit.prevent>
                <b-form-group label="Email" label-for="login-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Mot de passe</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="isOperating"
                  @click="validationForm"
                >
                  Connexion
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
      </b-row>
    </div>
    <div v-if="!isLoading && !isActive">
      <div class="misc-wrapper">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">Flashcar</h2>
        </b-link>

        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              Vous n'êtes pas autorisé à accéder à cette application
            </h2>
            <p class="mb-2">
              Votre abonnement est expiré. Veuillez contacter l'administration
            </p>
            <div class="my-3">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <feather-icon
                      class="text-primary"
                      size="20"
                      icon="PhoneIcon"
                  />
                </b-media-aside>
                <b-media-body style="margin-right: 25%">
                  <h6 class="mb-0"><a href="tel:+22969507171">+229 69 50 71 71</a></h6>
                </b-media-body>
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <feather-icon
                        class="text-primary"
                        size="20"
                        icon="MessageSquareIcon"
                    />
                  </b-media-aside>
                  <b-media-body style="margin-right: 55%">
                    <h6 class="mb-0"><a  target="_blank" href="mailto:flascar@waouhmonde.com">flascar@waouhmonde.com</a></h6>
                  </b-media-body>
                </b-media>
              </b-media>
              <hr />
            </div>

            <b-button
              variant="primary"
              class="mb-2 btn-sm-block"
              @click="contact()"
            >
              Renouveller votre abonnement
            </b-button>

            <b-img fluid :src="downImg" alt="Error page" style="width: 90%" />
          </div>
        </div>
      </div>

      <b-modal
        size="sm"
        id="operation-modal"
        content-class="shadow"
        variant="primary"
        v-model="modalinfo"
        title="Renouvellement"
        hide-footer
        centered
      >
        <b-form-group class="col pr-0">
          <validation-provider
              #default="{ errors }"
              name="chargeur"
          >
            <label for="chargeur">Type d'abonnement</label>
            <b-form-select
                id="chargeur"
                v-model="type_abonnement"
                :options="abonnement_types"
                value-field="value"
                @change="ckecktype"
                text-field="text"
            />
          </validation-provider>
        </b-form-group>
        <b-form-group class="col-sm-4 float-sm-right">
          <b-button block  @click="paiement()" variant="primary">
            Payer
          </b-button>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BFormSelect,
  BImg,
  BForm,
  BButton,
  BMedia,
  BCard,
  BMediaAside,
  BMediaBody,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
//import {openKkiapayWidget, addKkiapayListener} from "kkiapay";



export default {
  components: {
    BRow,
    BCol,
    BLink,
    BMedia,
    BCard,
    BFormSelect,
    BMediaAside,
    BMediaBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      modalinfo: false,
      isActive: false,
      informations: {},
      isLoading: true,
      amount_paiement:150000,
      type_abonnement:"annuel",
      downImg: require("@/assets/images/pages/unauthorized.jpg"),
      status: "",
      password: "",
      abonnement_types: [
        { text: "Annuel", value: "annuel" },
        { text: "A vie", value: "vie" },
      ],
      userEmail: "",
      isOperating: false,
      sideImg: require("@/assets/images/logo/logo-dark.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  beforeCreate() {
    let base_url = window.location.origin;
    let host = window.location.host;
    // host = host.replaceAll(":", "&");
    this.isLoading = true;
    let pathArray = window.location.pathname.split("/");
    this.$http
      .get(`/abonnement/${host}`)
      .then((res) => {
        this.isLoading = false;
        let response = res.data;
        this.informations = response.data;
        if (response.success) {
          this.isActive = true;
        } else {
          this.isActive = false;
          this.informations = response.message;
        }
      })
      .catch((e) => {
        this.isLoading = false;
      });
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/aff.jpg");
        return this.sideImg;
      }
      return this.sideImg;
    },

    dowmImgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.downImg = require("@/assets/images/pages/unauthorized.jpg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  mounted() {
    //addKkiapayListener('success',this.successHandler)
  },
  methods: {
    contact() {
      this.modalinfo = true;
    },
    ckecktype(item){
     if (item==="annuel"){
       this.amount_paiement=150000
     }else{
       this.amount_paiement=999900
     }
    },
    paiement(){
    /*  this.modalinfo = false;
      openKkiapayWidget({
        amount: 4000,
        api_key: "1fd074003bf311edafa2d398c4589a54",
        sandbox: true,
        phone: "97000000",
      });*/
    /*  openKkiapayWidget({amount:this.amount_paiement,position:"center",callback:"/login",
        data:"",
        theme:"#BF6000",
        sandbox: true,
        api_key:"1fd074003bf311edafa2d398c4589a54"})*/
    },
/*    successHandler(response) {
      response.data=window.location.host
      this.$http
          .post('/renouvellement/'+this.type_abonnement,response)
          .then((res) => {
            this.isLoading = false;
            console.log(res)
            if (res.status===200) {
              this.showToast(res.data, "success");
              location.reload()
            } else {
              this.showToast("Renouvellement d'abonnement échoué", "danger");
            }
          })
          .catch((e) => {
            this.isLoading = false;
          });
    },*/
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.isOperating = true;
          this.$http
            .post("/login", {
              email: this.userEmail,
              password: this.password,
            })
            .then((res) => {
              this.showToast("Connexion réussie", "success");
              this.isOperating = false;
              let use = res.data.data;
              localStorage.setItem("coco_act", use.state);
              this.$store.commit("LOGIN", true);
              this.$store.commit("AUTH_USER", res.data.data);
              localStorage.setItem("flash_token", res.data.data.token);
              localStorage.setItem("flash_sc", res.data.data.userScopes);
              localStorage.setItem(
                "subscription",
                JSON.stringify(this.informations)
              );
              localStorage.setItem(
                "societyAsImportateur",
                res.data.data.societyAsImportateur
              );
              location.reload();
              this.$router.push({ name: "home" });
            })
            .catch((e) => {
              this.isOperating = false;
              this.showToast(e.response.data.message, "danger");
            });
        }
      });
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/pages/page-misc.scss";
</style>
